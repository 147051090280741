import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BackButton } from '@stock-core/models/BackButton';

@Injectable({
  providedIn: 'root',
})
export class BackButtonService {
  backButtonSubject: BehaviorSubject<BackButton | undefined> = new BehaviorSubject<BackButton | undefined>(undefined);
  loading = true;
}
