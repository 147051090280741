import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppBarContentService {
  private contentSource = new BehaviorSubject<TemplateRef<typeof TemplateRef> | null>(null);
  appBarContent$ = this.contentSource.asObservable();

  setAppBarContent(content: TemplateRef<typeof TemplateRef>) {
    this.contentSource.next(content);
  }

  clearAppBarContent() {
    this.contentSource.next(null);
  }
}
