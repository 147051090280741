import { Component } from '@angular/core';

@Component({
  selector: 'rc-content-with-action',
  standalone: true,
  imports: [],
  templateUrl: './content-with-action.component.html',
  styleUrl: './content-with-action.component.scss',
})
export class ContentWithActionComponent {}
